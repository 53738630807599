import React, { useContext, useMemo } from "react";
import FormContext, {
  FORM_STAGE,
  RegistrationFormContextShape,
  STAGE,
} from "../contexts/form";
import { AadharRedirectPage } from "./Signup/AadharRedirect";
import { PersonalDetailsPage } from "./Signup/PersonalDetails";
import { ProcessForm } from "./Signup/ProcessForm";
import { SignupStageHandlerComponent } from "./Signup/SignupStageHandlerComponent.type";
import { VerifyAadharResponse } from "./Signup/VerifyAadhaarResponse";
import usePromise from "../utils/hooks/usePromise";
import { services } from "../services";
import { useAuth } from "../utils/hooks/useAuth";
import Loader from "../assets/animated-images/loader";
import BaseLayout from "../layouts/base";
import FormattedJsonViewer from "../components/formatted-json-viewer";
import { PAGE_PATHS } from "./page-paths";
import { Link } from "react-router-dom";

export default function SignupPage() {
  const { formStage, formState, setFieldValue, clearForm } = useContext(
    FormContext
  ) as RegistrationFormContextShape;

  const { vle } = useAuth();

  const { result: onboardingLimitInfo } = usePromise(
    {
      fn: async () => {
        if (vle?.id) {
          return services.requestSvc.get<{
            count: number;
            vleOnboardingLimit: number;
            stateOnboardingCount: number;
            stateOnboardingLimit: number;
          }>("/vle-onboarding-count?vleId=" + vle.id);
        }
      },
      runOnMount: { args: [] },
      runOnRerender: { enabled: true, args: [] },
    },
    [vle]
  );

  console.log("Form stage", formStage);

  const currentStageDetails = STAGE[formStage];

  const StageHandlerComponent = useMemo((): SignupStageHandlerComponent => {
    return {
      [FORM_STAGE.STARTED]: AadharRedirectPage,
      [FORM_STAGE.AADHAR_DETAILS_CAPTURED]: VerifyAadharResponse,
      [FORM_STAGE.AADHAR_DETAILS_VERIFIED]: PersonalDetailsPage,
      [FORM_STAGE.PERSONAL_DETAILS_CAPTURED]: PersonalDetailsPage,
      [FORM_STAGE.READY_FOR_SUBMISSION]: ProcessForm,
      [FORM_STAGE.SUCCESSFUL]: ProcessForm,
      [FORM_STAGE.FAILED]: ProcessForm,
    }[formStage];
  }, [formStage]);

  console.log("Onboarding limit info", onboardingLimitInfo);

  if (!onboardingLimitInfo) return <Loader />;
  else if (
    onboardingLimitInfo.stateOnboardingCount >=
      onboardingLimitInfo?.stateOnboardingLimit ||
    onboardingLimitInfo.count >= onboardingLimitInfo?.vleOnboardingLimit
  ) {
    return (
      <BaseLayout>
        <div className="py-4 my-6 w-full flex flex-col justify-center gap-y-4">
          <div className="w-full text-2xl text-red-500">
            Your onboarding limit has been reached
          </div>
          <FormattedJsonViewer
            json={{
              "Your Onboarding Count": onboardingLimitInfo.count,
              "VLE Onboarding Limit": onboardingLimitInfo.vleOnboardingLimit,
              "State Onboarding Count":
                onboardingLimitInfo.stateOnboardingCount,
              "Your State's Onboarding Limit":
                onboardingLimitInfo.stateOnboardingLimit,
            }}
          />

          <Link to={PAGE_PATHS.DEFAULT_NAVIGABLE}>
            <button className="btn-link">Go back</button>
          </Link>
        </div>
      </BaseLayout>
    );
  } else
    return (
      <StageHandlerComponent
        stepNumber={currentStageDetails.stepNumber}
        stepTitle={currentStageDetails.objective || ""}
        formStage={formStage}
        formState={formState}
        setFieldValue={setFieldValue}
        clearForm={clearForm}
      />
    );
}
