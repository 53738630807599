import React from "react";

// Function to format keys: camelCase, hyphenated, or underscored
const formatKey = (key: string) => {
  // Replace underscores and hyphens with spaces
  key = key.replace(/[_-]/g, " ");

  // Convert camelCase to space-separated words
  key = key.replace(/([a-z])([A-Z])/g, "$1 $2");

  // Capitalize first letter of each word
  return key.charAt(0).toUpperCase() + key.slice(1);
};

// Recursive component to render JSON
const JsonRenderer = ({ data }: { data: any }) => {
  if (Array.isArray(data)) {
    return (
      <ul>
        {data.map((item, index) => (
          <li key={index}>
            <JsonRenderer data={item} />
          </li>
        ))}
      </ul>
    );
  } else if (typeof data === "object" && data !== null) {
    return (
      <div>
        {Object.keys(data).map((key) => (
          <div key={key}>
            <strong>{formatKey(key)}:</strong> <JsonRenderer data={data[key]} />
          </div>
        ))}
      </div>
    );
  } else {
    return <span>{String(data)}</span>;
  }
};

// Main component that takes the JSON object as a prop
const FormattedJsonViewer = ({ json }: { json: any }) => {
  return <JsonRenderer data={json} />;
};

export default FormattedJsonViewer;
