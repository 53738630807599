import { LearnerService } from "./learner.service";
import { apiRequest } from "./request";

type ServiceCollection = {
  requestSvc: typeof apiRequest;
  learnerSvc: LearnerService;
};

export const services: ServiceCollection = {} as ServiceCollection;

export const resolveServices = async () => {
  const requestSvc = apiRequest;
  const learnerSvc = new LearnerService(requestSvc);

  Object.assign(services, {
    requestSvc,
    learnerSvc,
  });

  return true;
};
