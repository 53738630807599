import React from "react";
import { useParams } from "../utils/params";

export default function ErrorPage() {
  const { title = "An error occurred", message = "An unknown error occurred" } =
    useParams();

  return (
    <div className="centered-page">
      <div className="text-center text-2xl font-semibold">{title}</div>
      <div className="text-center">{message}</div>
    </div>
  );
}
