import React, { useCallback, useMemo, useState } from "react";
import { MdError } from "react-icons/md";

type ErrorConfig = { active?: boolean; title: string; message: string };

const DEFAULT_ERROR_TITLE = "An error occurred";
const DEFAULT_ERROR_MESSAGE = "An unknown error occurred";

export default function useError(props?: Partial<ErrorConfig>) {
  const [errorConfig, setErrorConfig] = useState<ErrorConfig>({
    active: false,
    title: props?.title || DEFAULT_ERROR_TITLE,
    message: props?.message || DEFAULT_ERROR_MESSAGE,
  });

  const throwError = useCallback((newErrorConfig?: ErrorConfig) => {
    setErrorConfig((currentErrorConfig) => ({
      ...currentErrorConfig,
      ...(newErrorConfig || {}),
      active: true,
    }));
  }, []);

  const reset = useCallback(() => {
    setErrorConfig({
      active: false,
      title: DEFAULT_ERROR_TITLE,
      message: DEFAULT_ERROR_MESSAGE,
    });
  }, [setErrorConfig]);

  const errorFragment = useMemo(() => {
    return errorConfig.active ? (
      <div className="flex items-center gap-x-3">
        <MdError color="red" size={50} />
        <div className="flex flex-col">
          <div className="text-2xl font-semibold">{errorConfig.title}</div>
          <div>{errorConfig.message}</div>
        </div>
      </div>
    ) : (
      <></>
    );
  }, [errorConfig]);

  return {
    active: errorConfig.active,
    throwError,
    reset,
    errorFragment,
  };
}
