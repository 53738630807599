export const condObj = (
  condition: boolean,
  trueObject: any,
  falseObject = {}
) => (condition ? trueObject : falseObject);

export const prependZeroes = (num: number, maxDigits: number) => {
  let numStr = num + "";

  while (numStr.length < maxDigits) numStr = "0" + numStr;

  return numStr;
};
