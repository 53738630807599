import { useContext, useEffect } from "react";
import Loader from "../assets/animated-images/loader";
import { useParams } from "../utils/params";
import FormContext, { RegistrationFormContextShape } from "../contexts/form";
import { useNavigate } from "react-router-dom";
import { PAGE_PATHS } from "./page-paths";
import useError from "../utils/hooks/useError";

export default function HandleAadharRedirectPage() {
  const navigate = useNavigate();

  const { setFieldValue, clearForm } = useContext(
    FormContext
  ) as RegistrationFormContextShape;

  const {
    aadhaar_uid_token: aadhaarUIDToken,
    payload_signature: payloadSignature,
  } = useParams();

  const { active, errorFragment, throwError } = useError();

  useEffect(() => {
    clearForm();

    const badRequest =
      typeof aadhaarUIDToken !== "string" ||
      typeof payloadSignature !== "string";

    if (!badRequest) {
      setFieldValue("aadhaarUIDToken", aadhaarUIDToken);
      setFieldValue("aadhaarUIDTokenSignature", payloadSignature);
      navigate(PAGE_PATHS.SIGNUP);
    } else {
      throwError({
        title: "Bad Request",
        message: "Invalid payload received",
      });
    }
  }, [
    aadhaarUIDToken,
    payloadSignature,
    setFieldValue,
    navigate,
    throwError,
    clearForm,
  ]);

  return (
    <div className="centered-page">
      {!active ? <Loader className="h-16" color="#999" /> : errorFragment}
    </div>
  );
}
