import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import HomePage from "./pages/Home";
import SignupPage from "./pages/Signup";
import { FormContextProvider } from "./contexts/form";
import HandleAadharRedirectPage from "./pages/HandleAadharRedirect";
import { resolveServices, services } from "./services";
import { PAGE_PATHS } from "./pages/page-paths";
import ErrorPage from "./pages/Error";
import usePromise from "./utils/hooks/usePromise";
import Loader from "./assets/animated-images/loader";
import { MockCSCAuthPage } from "./pages/MockCSCAuth";
import { useAuth } from "./utils/hooks/useAuth";

// Fonts
import "./assets/fonts/SegoeUIBoldItalic.ttf";
import "./assets/fonts/SegoeUIBold.ttf";
import "./assets/fonts/SegoeUIItalic.ttf";
import "./assets/fonts/SegoeUI.ttf";
import LoginPage from "./pages/Login";

function App() {
  const { result: servicesResolved } = usePromise(
    { fn: resolveServices, runOnMount: { args: [] } },
    []
  );

  const { is_authenticated } = useAuth();

  if (servicesResolved) {
    return (
      <FormContextProvider>
        <BrowserRouter>
          <Routes>
            {is_authenticated
              ? [
                  <Route
                    key={`authenticated-${PAGE_PATHS.SIGNUP}`}
                    path={PAGE_PATHS.SIGNUP}
                    Component={SignupPage}
                  />,
                  <Route
                    key={`authenticated-${PAGE_PATHS.MOCK_CSC_AUTH_PAGE}`}
                    path={PAGE_PATHS.MOCK_CSC_AUTH_PAGE}
                    Component={MockCSCAuthPage}
                  />,
                  <Route
                    key={`authenticated-${PAGE_PATHS.HANDLE_AADHAAR_AUTH_REDIRECT}`}
                    path={PAGE_PATHS.HANDLE_AADHAAR_AUTH_REDIRECT}
                    Component={HandleAadharRedirectPage}
                  />,
                  <Route
                    key={`authenticated-${PAGE_PATHS.ERROR}`}
                    path={PAGE_PATHS.ERROR}
                    Component={ErrorPage}
                  />,
                  <Route
                    key={`authenticated-${PAGE_PATHS.DEFAULT}`}
                    path={PAGE_PATHS.DEFAULT}
                    Component={HomePage}
                  />,
                ]
              : [
                  <Route
                    key={`unauthenticated-${PAGE_PATHS.DEFAULT}`}
                    path={PAGE_PATHS.DEFAULT}
                    Component={LoginPage}
                  />,
                ]}
          </Routes>
        </BrowserRouter>
      </FormContextProvider>
    );
  } else
    return (
      <div className="centered-page">
        <Loader style={{ height: "40px" }} color="#999" />
      </div>
    );
}

export default App;
