import { useNavigate } from "react-router-dom";
import { RegistrationFormContextShape } from "../../contexts/form";
import { services } from "../../services";
import usePromise from "../../utils/hooks/usePromise";
import { SignupStageHandlerComponent } from "./SignupStageHandlerComponent.type";
import { PAGE_PATHS } from "../page-paths";
import Loader from "../../assets/animated-images/loader";

export const VerifyAadharResponse: SignupStageHandlerComponent = ({
  formState,
  setFieldValue,
  clearForm,
}) => {
  const navigate = useNavigate();

  const { loading: verifying, error: verificationError } = usePromise(
    {
      fn: async (formState: RegistrationFormContextShape["formState"]) => {
        const res = await services.learnerSvc.checkLearnerDuplicacy(
          formState.aadhaarUIDToken || ""
        );

        setFieldValue("isTokenValid", true);

        if (res) navigate(PAGE_PATHS.SIGNUP);
      },
      runOnMount: {
        args: [formState],
      },
    },
    [formState]
  );

  return (
    <div className="centered-page font-semibold flex-col gap-y-4">
      {verifying ? (
        <span className="text-2xl">Verifying...</span>
      ) : verificationError ? (
        <>
          <span className="text-red-500 text-2xl">
            {verificationError.message || "An error occurred"}
          </span>
          <button onClick={clearForm} className="btn-primary">
            Go back
          </button>
        </>
      ) : (
        <span className="text-2xl">Verification successful!</span>
      )}
    </div>
  );
};
