import { RequestService } from "./request";

export class LearnerService {
  constructor(private requestSvc: RequestService) {}

  checkLearnerDuplicacy(aadhaarUIDToken: string) {
    return this.requestSvc.post("/check-learner-duplicacy-by-uid-token", {
      aadhaarUIDToken,
    });
  }
}
