import React from "react";
import logo from "../assets/logo.png";
import unicef from "../assets/UNICEF-logo.png";
import csc from "../assets/csc.png";
export default function BaseLayout({
  noIcon,
  children,
}: {
  readonly noIcon?: boolean;
  readonly children: any;
}) {
  return (
    <div className="flex items-center justify-center bg-img">
      <div className="w-full p-8 bg-white rounded-lg flex justify-center max-w-[600px]">
        <div className="w-full">
          {!noIcon && (
            <div className="flex justify-between items-center w-full space-x-4 mt-4">
              <img alt="" src={unicef} className="h-7 sm:h-10 md:h-12" />
              <img alt="" src={logo} className="h-7 sm:h-10 md:h-12" />
              <img alt="" src={csc} className="h-7 sm:h-10 md:h-12" />
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}
