import React from "react";
import { Link } from "react-router-dom";
import { PAGE_PATHS } from "./page-paths";

export const MockCSCAuthPage = () => {
  return (
    <div>
      {/* saved from url=(0049)https://webdemogateway.csccloud.in/authsys/webotp */}
      <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
      <title>Auth Through Otp | CSC e-Governance Services India Limited</title>
      {/* Required meta tags */}
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      {/* Bootstrap and style CSS */}
      <link
        rel="stylesheet"
        href="./aadhaar_auth_page_files/bootstrap.min.css"
      />
      <link rel="stylesheet" href="./aadhaar_auth_page_files/style.css" />
      {/* Logo container */}
      <section className="logo_header">
        <div className="container">
          <div className="row">
            <div className="col col-6">
              <img
                src="./aadhaar_auth_page_files/csc_x45.png"
                className="h30"
              />
            </div>
            <div className="col col-6 text-right">
              <img
                src="./aadhaar_auth_page_files/digi_x50.png"
                className="h30"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Logo container end */}
      <div className="my_overlay" id="overlay" style={{ display: "none" }}>
        <div className="span">
          <div className="timer" />
          <div className="load">Loading...</div>
        </div>
      </div>
      {/* Main content */}
      <section className="col_back">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="form-inline">
                <label
                  className="custom-control custom-checkbox"
                  htmlFor="check"
                >
                  <input
                    id="check"
                    name="agree_check"
                    type="checkbox"
                    className="custom-control-input"
                  />
                  <span className="custom-control-indicator cust_check" />
                  <span className="custom-control-description chk_fix">
                    I, the holder of Aadhaar Number to be mentioned below,
                    hereby give my consent to CSCe-Governance Services India
                    Limited (CSC) to obtain my Aadhaar Number and Name for
                    authentication with UIDAI. CSC has informed me that my
                    identity information would only be used for the purpose of
                    Authentication and alsoinformed that details will not be
                    shared with anyone else. Upon authentication with UIDAI, CSC
                    will receive my personal data such as First and Last Name,
                    Date of Birth, Mobile Number, Aadhaar Number, Photo, Address
                    and Email ID etc. from UIDAI and be used for Authentication.
                    <br />
                    I certify that my age is above 18 years.
                    <img
                      id="button"
                      src="./aadhaar_auth_page_files/medium-volume.png"
                      style={{ height: "24px", cursor: "pointer" }}
                    />
                    <audio>
                      <source src="https://payuat.csccloud.in/registration/assets/consent_speech/aadhar_concent_english.mp3" />
                    </audio>
                  </span>
                </label>
                <hr />
                <label className="custom-control">
                  <span className="custom-control-description chk_fix">
                    मैं, नीचे उल्लिखित आधार नंबर का धारक, यूआईडीएआई के साथ
                    प्रमाणीकरण के लिए अपना आधार नंबर और नाम प्राप्त करने के लिए
                    सीएससीई-गवर्नेंस सर्विसेज इंडिया लिमिटेड (सीएससी) को अपनी
                    सहमति देता हूं। सीएससी ने मुझे सूचित किया है कि मेरी पहचान
                    की जानकारी का उपयोग केवल प्रमाणीकरण के उद्देश्य के लिए किया
                    जाएगा और यह भी सूचित किया है कि विवरण किसी और के साथ साझा
                    नहीं किया जाएगा। यूआईडीएआई के साथ प्रमाणीकरण पर, सीएससी को
                    मेरा व्यक्तिगत डेटा जैसे पहला और अंतिम नाम, जन्म तिथि,
                    मोबाइल नंबर, आधार नंबर, फोटो, पता और ईमेल आईडी आदि यूआईडीएआई
                    से प्राप्त होगा और प्रमाणीकरण के लिए उपयोग किया जाएगा।
                    <br />
                    मैं प्रमाणित करता हूं कि मेरी आयु 18 वर्ष से अधिक है।
                    <img
                      id="button"
                      src="./aadhaar_auth_page_files/medium-volume.png"
                      style={{ height: "24px", cursor: "pointer" }}
                    />
                    <audio>
                      <source src="https://payuat.csccloud.in/registration/assets/consent_speech/aadhar_concent_hindi.mp3" />
                    </audio>
                  </span>
                </label>
              </div>
              <br />
              <div className="form-inline">
                <div className="smooth_font">
                  <p className="u11">Aadhaar Number / VID / UID Token</p>
                </div>
                <div className="smooth_font aadhar f600">
                  <span className="sbox">xxxx-xxxx-3922</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="method">
        <div className="container method-container">
          <div className="row">
            <div className="col col-12 col-md-1">
              <div className="smooth_font f600 title1">
                <p>Mode</p>
              </div>
            </div>
            <div className="col col-12 col-md-6">
              <div className="form-inline radio_group">
                <div className="form-group">
                  <label
                    data-value="otp"
                    className="custom-control custom-radio radio_fix"
                  >
                    <input
                      id="otp"
                      name="radio-stacked"
                      type="radio"
                      className="custom-control-input"
                      defaultChecked
                    />
                    <span className="custom-control-indicator" />
                    <span className="custom-control-description">OTP</span>
                  </label>
                </div>
                <div className="form-group">
                  <label
                    data-value="fmr"
                    className="custom-control custom-radio radio_fix"
                  >
                    <input
                      id="fmr"
                      name="radio-stacked"
                      type="radio"
                      className="custom-control-input"
                      disabled
                    />
                    <span className="custom-control-indicator" />
                    <span className="custom-control-description">
                      Finger Print
                    </span>
                  </label>
                </div>
                <div className="form-group">
                  <label
                    data-value="iris"
                    className="custom-control custom-radio radio_fix"
                  >
                    <input
                      id="iris"
                      name="radio-stacked"
                      type="radio"
                      className="custom-control-input"
                      disabled
                    />
                    <span className="custom-control-indicator" />
                    <span className="custom-control-description">IRIS</span>
                  </label>
                </div>
                <div className="form-group">
                  <label
                    data-value="fid"
                    className="custom-control custom-radio radio_fix"
                  >
                    <input
                      id="fid"
                      name="radio-stacked"
                      type="radio"
                      className="custom-control-input"
                      disabled
                    />
                    <span className="custom-control-indicator" />
                    <span className="custom-control-description">Facial</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="col col-12 col-md-5">
              <div className="clock">
                <span className="exp_time">Session expires in</span>
                <h5 id="sess_tmr">09:43</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="genrate">
        <div className="container">
          <div className="row">
            <div className="col col-12 col-md-12">
              <div
                className="alert alert-success p2015 radius0"
                style={{ display: "none" }}
                id="otp_msg"
              />{" "}
            </div>
            <div className="col col-12 col-md-6 pl3 mt15">
              <div className="form-group">
                <p className="smooth_font f600 title1">
                  Please generate OTP now
                </p>
              </div>
              <div className="form-inline mt25">
                <label className="custom-control custom-checkbox" htmlFor="sms">
                  <input
                    id="sms"
                    name="sms"
                    type="checkbox"
                    className="custom-control-input"
                    defaultChecked
                  />
                  <span className="custom-control-indicator cust_check" />
                  <span className="custom-control-description chk_fix">
                    SMS
                  </span>
                </label>
                <label
                  className="custom-control custom-checkbox"
                  htmlFor="email"
                >
                  <input
                    id="email"
                    name="email"
                    type="checkbox"
                    className="custom-control-input"
                    defaultChecked
                  />
                  <span className="custom-control-indicator cust_check" />
                  <span className="custom-control-description chk_fix">
                    EMAIL
                  </span>
                </label>
              </div>
              <div className="form-group mt25">
                <button
                  className="btn btn-primary btn-sm point"
                  id="send_otp"
                  style={{ display: "none" }}
                >
                  Generate OTP
                </button>
                <a
                  className="btn btn-danger btn-sm ml10 point"
                  href="https://webdemogateway.csccloud.in/auth/actioncancel"
                >
                  Cancel
                </a>
                <input
                  type="hidden"
                  id="otp_id"
                  defaultValue="fe1e9f8596154f1d1b99ab1d988996ed"
                />
                <input
                  type="hidden"
                  id="public_key"
                  defaultValue="-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC9BLl8HcSzbdYg5S50RfMznsJf
zzefjAUuYkWS9vViuzs4vw59bbEsIu4bjAlyyTusg8luuXPA9SvO4zYgj1rxe42f
i+tISEuwIcL0f0I+PgmbIBPOhSnTVYmXFmtbZxXgSfLYyzq0WGNZDLJ0s5V1cE6e
EFTb/F6ZCp79sd+/UQIDAQAB
-----END PUBLIC KEY-----"
                />
              </div>
            </div>
            <div
              className="col col-12 col-sm-6 pl3 mt15"
              id="otp_form"
              style={{ display: "none" }}
            >
              <div className="form-group">
                <p className="smooth_font f600 title1">Verify OTP</p>
              </div>
              <form className="form-inline" autoComplete="off" id="otpfrm">
                <input
                  type="text"
                  id="txt_otp"
                  className="form-control radius0 disabledAutoFillPassword"
                  placeholder="Enter OTP"
                  autoComplete="nope"
                  maxLength={6}
                  name="rjkdmff"
                />
              </form>
              <span className="text1">
                Please validate the OTP within 10 minutes of receiving it
              </span>
              <div className="form-group mt15">
                <button
                  className="btn btn-success btn-sm point"
                  id="validate_otp"
                >
                  Validate OTP
                </button>
                <a
                  className="btn btn-danger btn-sm ml10 point"
                  href="https://webdemogateway.csccloud.in/auth/actioncancel"
                >
                  Cancel
                </a>
              </div>
            </div>
            <form
              method="post"
              action="https://p2e.csccloud.in/handle-aadhaar-auth-redirect"
              id="postform"
            >
              <input type="hidden" name="res_data" id="res_data" />
              <input type="hidden" name="client_id" defaultValue="CSC-UNI" />
              <input type="hidden" name="param" defaultValue="N" />
            </form>
          </div>
        </div>
      </section>
      {/* Main content end */}
      <section className="origin">
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="requester smooth_font">
                Authentication Request Originated By <strong />
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="foot">
        <div className="container">
          <div className="row">
            <div className="col col-md-8 text-left">
              <span>
                Copyright © 2024 CSC e-Governance Services India Limited. All
                rights reserved
              </span>
            </div>
            <div className="col col-md-4 text-right">
              <strong>Version 2.5</strong>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
