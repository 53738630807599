import { FormikErrors } from "formik";

export const renderFieldError = (
  errors: FormikErrors<{ [key: string]: string }>,
  fieldName: string
) => {
  if (errors[fieldName])
    return <div className="text-red-500">{errors[fieldName]}</div>;
  return <></>;
};
