import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CreateLearnerDTO } from "@unicef/shared-resources/dto/create-learner";
import { z } from "zod";
import _ from "lodash";

export const FORM_STAGE = {
  STARTED: "STARTED" as const,
  AADHAR_DETAILS_CAPTURED: "AADHAR_DETAILS_CAPTURED" as const,
  AADHAR_DETAILS_VERIFIED: "AADHAR_DETAILS_VERIFIED" as const,
  PERSONAL_DETAILS_CAPTURED: "PERSONAL_DETAILS_CAPTURED" as const,
  READY_FOR_SUBMISSION: "READY_FOR_SUBMISSION" as const,
  SUCCESSFUL: "SUCCESSFUL" as const,
  FAILED: "FAILED" as const,
};

export const STAGE: {
  [stageName: string]: {
    stepNumber: number;
    objective?: string;
  };
} = {
  [FORM_STAGE.STARTED]: {
    stepNumber: 1,
    objective: "Aadhaar Verification",
  },
  [FORM_STAGE.AADHAR_DETAILS_CAPTURED]: {
    stepNumber: 2,
    objective: "Processing Aadhaar Response",
  },
  [FORM_STAGE.AADHAR_DETAILS_VERIFIED]: {
    stepNumber: 3,
    objective: "Enter your basic details",
  },
  [FORM_STAGE.PERSONAL_DETAILS_CAPTURED]: {
    stepNumber: 4,
    objective: "Provide VLE details",
  },
  [FORM_STAGE.READY_FOR_SUBMISSION]: {
    stepNumber: 5,
  },
  [FORM_STAGE.SUCCESSFUL]: {
    stepNumber: 6,
  },
  [FORM_STAGE.FAILED]: {
    stepNumber: 6,
  },
};

export type FormStage = (typeof FORM_STAGE)[keyof typeof FORM_STAGE];

type RegistrationForm = Partial<
  z.infer<typeof CreateLearnerDTO> & {
    isTokenValid: boolean;
    isPersonalDetailsFinalised: boolean;
    isPersonalDetailsVerified: boolean;
    personalDetailsErr: string;
    isFormSubmitted: boolean;
    isSuccessful: boolean;
    formSubmissionError: string;
  }
>;

export type RegistrationFormContextShape = {
  formStage: FormStage;
  formState: RegistrationForm;
  setFieldValue: (
    fieldName: keyof RegistrationForm,
    value: boolean | string | number | undefined
  ) => void;
  getFieldValue: (
    fieldName: keyof RegistrationForm
  ) => string | bigint | boolean | undefined | number;
  clearForm: () => void;
};

const FormContext = createContext<RegistrationFormContextShape | {}>({});

export const FormContextProvider = ({ children }: FormContextProviderProps) => {
  const [formValues, setFormValues] = useState<RegistrationForm>(
    getFormValuesFromLocalStorage()
  );

  const formStage = useMemo(() => getFormStage(formValues), [formValues]);

  const setFieldValue: RegistrationFormContextShape["setFieldValue"] =
    useCallback(
      (fieldName, value) => {
        Object.assign(formValues, { [fieldName]: value });
        localStorage.setItem("formState", JSON.stringify(formValues));
        setFormValues({ ...formValues });
      },
      [setFormValues, formValues]
    );

  const getFieldValue: RegistrationFormContextShape["getFieldValue"] =
    useCallback(
      (fieldName) => {
        return formValues[fieldName];
      },
      [formValues]
    );

  const clearForm: RegistrationFormContextShape["clearForm"] =
    useCallback(() => {
      for (const key in formValues) {
        delete formValues[key as keyof typeof formValues];
      }
      setFormValues({ ...formValues });
      localStorage.setItem("formState", "{}");
    }, [formValues]);

  const contextValue: RegistrationFormContextShape = useMemo(
    () => ({
      formStage,
      formState: formValues,
      setFieldValue,
      getFieldValue,
      clearForm,
    }),
    [formStage, formValues, setFieldValue, getFieldValue, clearForm]
  );

  /*
   *
   *
   *
   *
   * Form state rectifiers
   *
   *
   *
   *
   *  */

  useEffect(() => {
    if (
      !formValues.isPersonalDetailsFinalised &&
      formValues.isPersonalDetailsVerified
    )
      setFieldValue("isPersonalDetailsVerified", false);
  }, [
    formValues.isPersonalDetailsFinalised,
    formValues.isPersonalDetailsVerified,
    setFieldValue,
  ]);

  useEffect(() => {
    if (!formValues.isPersonalDetailsVerified && formValues.isFormSubmitted) {
      setFieldValue("isFormSubmitted", false);
      setFieldValue("isSuccessful", false);
    }
  }, [
    formValues.isPersonalDetailsVerified,
    formValues.isFormSubmitted,
    setFieldValue,
  ]);

  return (
    <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>
  );
};

const getFormValuesFromLocalStorage = (): RegistrationForm =>
  JSON.parse(localStorage.getItem("formState") || "{}");

type FormContextProviderProps = {
  children: any;
};

const getFormStage = (formState: RegistrationForm): FormStage => {
  let formStage: FormStage = FORM_STAGE.STARTED;

  if (formState.aadhaarUIDTokenSignature && formState.aadhaarUIDToken)
    formStage = FORM_STAGE.AADHAR_DETAILS_CAPTURED;
  if (formState.isTokenValid) formStage = FORM_STAGE.AADHAR_DETAILS_VERIFIED;
  if (
    formState.mobileNumber &&
    formState.name &&
    formState.gender &&
    formState.isPersonalDetailsFinalised
  )
    formStage = FORM_STAGE.PERSONAL_DETAILS_CAPTURED;
  if (formState.isPersonalDetailsVerified)
    formStage = FORM_STAGE.READY_FOR_SUBMISSION;
  if (formState.isFormSubmitted) {
    if (!formState.isSuccessful) formStage = FORM_STAGE.FAILED;
    else formStage = FORM_STAGE.SUCCESSFUL;
  }

  return formStage;
};

export default FormContext;

declare global {
  interface Window {
    printFormState: () => void;
  }
}

window.printFormState = () => {
  const formState = JSON.parse(localStorage.getItem("formState") || "{}");
  const inputValuesFields: (keyof RegistrationForm)[] = [
    "name",
    "email",
    "mobileNumber",
    "gender",
    "aadhaarUIDTokenSignature",
    "aadhaarUIDToken",
  ];
  const inputValues = _.pick(formState, inputValuesFields);
  const formActivityState = _.omit(formState, inputValuesFields);
  console.log({
    inputValues,
    formActivityState,
  });
};
