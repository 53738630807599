import React, { useCallback } from "react";
import BaseLayout from "./base";
import { useNavigate } from "react-router-dom";
import { PAGE_PATHS } from "../pages/page-paths";

export default function FormLayout({
  children,
  hideStepNumberInfo,
  stepNumber,
  stepTitle,
  stepIcon,
  stepDescription,
  clearForm,
}: {
  readonly children: any;
  readonly stepNumber: number;
  readonly hideStepNumberInfo?: boolean;
  readonly stepTitle: string;
  readonly stepIcon?: any;
  readonly stepDescription?: string;
  readonly clearForm?: (...args: any[]) => any;
}) {
  const navigate = useNavigate();

  const canClearForm = typeof clearForm === "function";

  const cancelApplication = useCallback(() => {
    const confirmed = window.confirm(
      "Are you sure you want to cancel this application?"
    );
    if (confirmed && canClearForm) {
      clearForm();
      navigate(PAGE_PATHS.DEFAULT_NAVIGABLE);
    }
  }, [canClearForm, clearForm, navigate]);

  return (
    <BaseLayout>
      {!hideStepNumberInfo && (
        <p className="pt-6">
          <i>Step {stepNumber} of 4</i>
        </p>
      )}

      <div className="flex gap-x-3 items-center mt-3">
        {stepIcon}
        <h2 className="pt-4 pb-4 text-3xl">{stepTitle}</h2>
        {stepDescription && (
          <div className="mb-3 text-xs sm:text-sm tracking-wide text-gray-600 font-bold">
            {stepDescription}
          </div>
        )}
      </div>
      <div className="mb-5">{children}</div>

      {typeof clearForm === "function" && (
        <div className="w-full text-right">
          <button onClick={cancelApplication} className="btn-link">
            Cancel application?
          </button>
        </div>
      )}
    </BaseLayout>
  );
}
