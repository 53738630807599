import { useCallback } from "react";
import BaseLayout from "../layouts/base";
import {
  cscConnectApiBaseUri,
  cscConnectClientId,
  cscConnectCallbackUri,
  resourcesUrl,
} from "../config";
import { openP2EPortal } from "../utils/redirect";
import { PAGE_PATHS } from "./page-paths";
import { useNavigate } from "react-router-dom";
import usePromise from "../utils/hooks/usePromise";
import { services } from "../services";
import { prependZeroes } from "../utils/func";

export default function LoginPage() {
  const navigate = useNavigate();

  const { loading: redirectingToCSCCLogin, run: redirectToCSCCLogin } =
    usePromise(
      {
        fn: async () => {
          const { count: cscAuthAttemptsCount } =
            await services.requestSvc.get<{
              count: number;
            }>("/get-incremented-cscc-auth-attempts-count");

          const redirectURL = `${cscConnectApiBaseUri}/authorize?response_type=code&client_id=${cscConnectClientId}&redirect_uri=${encodeURIComponent(
            cscConnectCallbackUri
          )}&state=${prependZeroes(cscAuthAttemptsCount, 8)}`;

          window.location.href = redirectURL;
        },
      },
      []
    );

  const redirectToP2EPortal = useCallback(async () => {
    openP2EPortal();
    navigate(PAGE_PATHS.DEFAULT_NAVIGABLE);
  }, []);

  return (
    <BaseLayout>
      <div className="flex flex-col mt-8 gap-y-4">
        <span className="text-3xl">
          Welcome to UNICEF Learner Pre-registration Portal!
        </span>
        <div className="my-4">
          <span className="italic text-lg text-gray-600">
            <span className="font-bold">Notice</span>: All P2E courses are free
            of cost
          </span>
        </div>
        <button
          className="btn-primary w-full"
          onClick={redirectToCSCCLogin}
          disabled={redirectingToCSCCLogin}
        >
          {redirectingToCSCCLogin ? "Please wait..." : "Login via CSC Connect"}
        </button>

        <button className="btn-primary w-full" onClick={redirectToP2EPortal}>
          Login as P2E Registered Learner
        </button>

        <a href={resourcesUrl} target="_blank">
          <button className="btn-secondary w-full">View Resources</button>
        </a>
      </div>
    </BaseLayout>
  );
}
