import axios, {
  AxiosInterceptorManager,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { apiBaseUri } from "../config";

export type RequestService = {
  interceptors: {
    request: AxiosInterceptorManager<InternalAxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse>;
  };
  request<T = any>(config: AxiosRequestConfig): Promise<T>;
  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T>;
  put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T>;
  patch<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T>;
};

export const apiRequest = axios.create({
  baseURL: apiBaseUri,
}) as RequestService;

apiRequest.interceptors.response.use(
  (res: AxiosResponse) => {
    return res.data.data;
  },
  (error: any) => {
    const { data } = error.response;

    const { code, data: errorData, message, statusCode } = data;
    const detailedMessage = typeof errorData === "string" && errorData;

    throw new APIError(detailedMessage || message, code, data, statusCode);
  }
);

const authInterceptor = {
  current: apiRequest.interceptors.request.use((config) => config),
};
export const setAccessToken = (accessToken?: string) => {
  authInterceptor.current = apiRequest.interceptors.request.use((config) => {
    config.headers.Authorization = !accessToken ? "" : "Bearer " + accessToken;
    return config;
  });
};

export class APIError extends Error {
  constructor(
    public readonly message: string,
    public readonly code: string,
    public readonly data: any = {},
    public readonly statusCode: number
  ) {
    super(message);
    this.name = "APIError";
  }
}
