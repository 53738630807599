import { Link } from "react-router-dom";
import FormLayout from "../../layouts/form";
import { useState } from "react";
import { SignupStageHandlerComponent } from "./SignupStageHandlerComponent.type";
import { PAGE_PATHS } from "../page-paths";
import { apiBaseUri, isProd } from "../../config";

export const AadharRedirectPage: SignupStageHandlerComponent = ({
  stepNumber,
  stepTitle,
}) => {
  const [actionMode, setActionMode] = useState("OTP");
  const [aadhaarNumber, setAadhaarNumber] = useState("");

  const proceedFunc = async () => {
    if (isProd) {
      window.location.href = `${apiBaseUri}/redirect-to-aadhaar-auth-gateway?aadhaar_number=${aadhaarNumber}&action_mode=${actionMode}`;
    } else {
      window.location.href = `${apiBaseUri}/mock-aadhaar-auth?aadhaar_number=${aadhaarNumber}`;
    }
  };

  return (
    <FormLayout
      stepNumber={stepNumber}
      stepTitle={stepTitle || ""}
      stepIcon={
        <img
          src={require("../../assets/aadhaar-icon.png")}
          className="h-12"
          alt="aadhaar-icon"
        />
      }
      hideStepNumberInfo
    >
      <div>
        <p className="mb-8 mt-3">
          Before you can register, we require you to perform Aadhaar
          verification along with providing a few details. <br />
          <br />
          <b>Note:</b> Do not navigate away from this page during the
          verification process.
        </p>
        <div className="bg-white rounded-lg">
          <div className="mb-4">
            <label htmlFor="aadhaar" className="block mb-2 font-semibold">
              Enter Aadhaar Number / VID
            </label>
            <input
              id="aadhaar"
              type="text"
              placeholder="Enter Aadhaar Number / VID"
              className="input w-full p-2 border rounded-lg"
              onChange={(e) => setAadhaarNumber(e.target.value)}
            />
          </div>

          <div className="mb-6">
            <p className="font-semibold mb-4">Action Mode</p>
            <label className="mr-4">
              <input
                type="radio"
                name="actionMode"
                value="OTP"
                checked={actionMode === "OTP"}
                onChange={() => setActionMode("OTP")}
              />{" "}
              OTP
            </label>
            <label>
              <input
                type="radio"
                name="actionMode"
                value="Fingerprint"
                checked={actionMode === "FMR"}
                onChange={() => setActionMode("FMR")}
              />{" "}
              Fingerprint
            </label>
          </div>
        </div>
        <button className="btn-primary w-full mt-2" onClick={proceedFunc}>
          Proceed
        </button>
      </div>
      <div className="w-full text-right mt-3">
        <Link to={PAGE_PATHS.DEFAULT_NAVIGABLE}>
          <button className="btn-link">Cancel application?</button>
        </Link>
      </div>
    </FormLayout>
  );
};
