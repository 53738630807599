import React, { useCallback, useEffect, useRef, useState } from "react";

export default function usePromise<T>(
  {
    fn,
    runOnMount,
    runOnRerender,
  }: {
    fn: (...args: any[]) => Promise<T>;
    runOnMount?: {
      args: Parameters<typeof fn>;
    };
    runOnRerender?: {
      enabled: boolean;
      args: Parameters<typeof fn>;
    };
  },
  deps: any[]
) {
  const callbackFn = useCallback(fn, deps);

  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useState<T>();
  const [error, setError] = useState<any>();

  const run = useCallback(
    async (...args: Parameters<typeof fn>) => {
      setLoading(true);

      const response = await callbackFn(...args)
        .then((res) => setResult(res))
        .catch(setError);

      setLoading(false);
      return response;
    },
    [callbackFn]
  );

  const ranOnMount = useRef(false);

  useEffect(() => {
    if (runOnMount && !ranOnMount.current) {
      run(...runOnMount.args);
      ranOnMount.current = true;
    } else if (runOnRerender?.enabled) {
      run(...runOnRerender.args);
    }
  }, deps);

  return { run, loading, result, error };
}
